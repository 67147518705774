import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Toolbar,
    IconButton,
    Typography,
    InputBase,
    Badge,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import {
    Menu as MenuIcon,
    Search as SearchIcon,
    Mail as MailIcon,
    Notifications as NotificationsIcon,
    Settings,
} from '@mui/icons-material';
import { Mobile, Account, Language } from './Popover';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
            '&:focus': {
                width: '100ch',
            },
        },
        [theme.breakpoints.up('sm')]: {
            width: '20ch',
            '&:focus': {
                width: '50ch',
            },
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '.2ch',
            '&:focus': {
                width: '18ch',
            },
        },
    },
}));

export default function PrimarySearchAppBar({ handleDrawer, user, handleThemDrawer }) {
    const { t } = useTranslation();
    return (
        <>
            <Toolbar>
                <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    aria-label='open drawer'
                    onClick={handleDrawer}
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    variant='h6'
                    noWrap
                    component={Link}
                    to='/'
                    color='inherit'
                >
                    {t("menu.efficient_trading")}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                {/* <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder={t('menu.search')}
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </Search> */}
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    {/* <IconButton size='large' aria-label='show 4 new mails' color='inherit'>
                        <Badge badgeContent={4} color='error'>
                            <MailIcon />
                        </Badge>
                    </IconButton>
                    <IconButton
                        size='large'
                        aria-label='show 17 new notifications'
                        color='inherit'
                    >
                        <Badge badgeContent={17} color='error'>
                            <NotificationsIcon />
                        </Badge>
                    </IconButton> */}
                    <IconButton
                        size='large'
                        color='inherit'
                        onClick={handleThemDrawer}
                    >
                        <Settings />
                    </IconButton>
                </Box>

                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                    {/* <Mobile /> */}
                </Box>
                <Language />
                {user ? <Account user={user} /> : null}
            </Toolbar>
        </>
    );
}