const menu = {
    home: 'Home',
    dashboard: 'Dashboard',
    efficient_trading: 'Efficient Trading',

    pages: 'Pages',
    update_page: 'Update Page',

    blogs: 'Blogs',
    new_blog: 'New Blog',
    update_blog: 'Update Blog',
    blog_details: 'Blog Details',

    user: 'User',
    new_user: 'New User',
    update_user: 'Update User',
    user_details: 'User Details',

    search: 'Search..',
    messages: 'Messages',
    notifications: 'Notifications',
    profile: 'Profile',
    change_password: 'Change password',
    logout: 'Logout',

    update:'Update',
    create: 'Create',
    edit: 'Edit',
    view: 'View',
    convert: 'Convert',
    send_email: 'Send Email',
    save:'Save',
    cancel:'Cancel',

    contact: 'Contact',
    newsLetter: 'News Letter',
    events: 'Events',
    engagement: 'Engagement',
    donation: 'Donation',

    index_pages:'Pages Index',
    abonnement:'Subscription',
    abonnement_index:'Subscription Index',
    faq:'FAQ',
    mentions_legales:'Legal Notice',
    inscription :'Registration',
    footer:'Footer',

    abonnement_update:'Update Subscriptions',
    abonnement_index_update:'Update Subscriptions Index',
    faq_update:'FAQ Update',
    mentions_légales_update:'Update legal notices',
    home_update:'Home Update',
    footer_update:'Footer Update',
};
export default menu;