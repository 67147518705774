const attribute = {

    name: 'Name',
    first_name: 'First name',
    last_name: 'Last name',
    full_name: 'Full name',
    email: 'Email',
    closing_date: 'Closing Date',
    password: 'Password',
    conform_password: 'Conform Password',
    phone: 'Phone',
    country: 'Country',
    background: 'Background',
    date: 'Date',
    comments: 'Comments',
    testimony: 'Testimony',
    read_all: 'Read all',
    age: 'Age',

    id: 'Id',
    identifier: 'Identifier',
    page: 'Page',
    container: 'Container',
    text: 'Text',
    images: 'Images',
    detailed_image: 'Detailed Image',
    avatar: 'Avatar',
    icon: 'Icon',
    map: 'Map',
    buttons: 'Buttons',
    form: 'Form',
    minimum_date: 'Minimum Date',
    maximum_date: 'Maximum Date',
    select_date: 'Select Date',
    selected_date: 'Selected Date',

    title: 'Title',
    en_title: 'En Title',
    fr_title: 'Fr Title',
    short_description: 'Short Description',
    en_short_description: 'En Short Description',
    fr_short_description: 'Fr Short Description',
    en_long_description: 'En Long Description',
    fr_long_description: 'Fr Long Description',
    en_category: 'En Category',
    fr_category: 'Fr Category',
    view_count: 'View Count',
    authore: 'Authore',
    en_authore: 'En Authore',
    fr_authore: 'Fr Authore',
    category: 'Category',
    en_category: 'En Category',
    fr_category: 'Fr Category',
    status: 'Status',
    subject: 'Subject',
    message: 'Message',
    language: 'Language',

    minimum: 'Minimum',
    maximum: 'Maximum',
    actual_value: 'Actual value',

    text_en: 'Text En',
    text_fr: 'Text Fr'
};
export default attribute;