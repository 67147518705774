const auth = {
    email: 'Adresse e-mail',
    password: 'Mot de passe',
    old_password: 'ancien mot de passe',
    confirm_password: 'Mot de passe de confirmation',
    login: 'Connexion',
    remember_me: 'Souviens-toi de moi',
    login_forgot_password: 'Mot de passe oublié?',
    forgot_password: 'Mot de passe oublié',
    already_have_an_account: 'Vous avez déjà un compte?',
    password_reset: 'Réinitialisation du mot de passe',
    email_verify_message: 'Merci pour votre inscription! Avant de commencer, pourriez-vous vérifier votre adresse e-mail en cliquant sur le lien que nous venons de vous envoyer ? Si vous n\'avez pas reçu l\'e-mail, nous vous en enverrons un autre avec plaisir.',
    resend_verification_email: 'Renvoyer l\'e-mail de vérification',
    password_changed_successfully: 'Le mot de passe a été changé avec succès',
    profile_updated_successfully: 'Mise à jour du profil réussie',
    reset_link_is_sent_to_your_email: 'Le lien de réinitialisation est envoyé à votre adresse e-mail',
    login_successfully: 'Connectez-vous avec succès'
};
export default auth;