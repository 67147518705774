import axios from "../plugin/axios";
import notistack from "../plugin/notistack";

const pages = {
  list() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`pages`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },


  getLegalNoticeData() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`page/3`)
        .then(({ data }) => {

          resolve(data);
        })
        .catch((error) => { reject(error) });
    });
  },
  setlegalNotice(data,id) {
    
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .put(`update-page/3/${id}`, data)
        .then(({ data }) => {
        //   console.log('data', data);
          resolve(data);
        })
        .catch((error) => { reject(error) });
    });
  },

  getHomePage() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`page/1`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  homeUpdate(data, id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .put(`update-page/1/${id}`, data)
        .then(({ data }) => {
          notistack.success(data.success)
          resolve(data);
        })
        .catch(({ response }) => {
          notistack.error()
          notistack.error(data.error)
          reject(response);
        });
    });
  },
  getFaqPage() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`page/4`)
        .then(({ data }) => {
          resolve(data);

        })
        .catch(({ response }) => {
          reject(response);

        });
    });
  },

  faqUpdate(data, id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .put(`update-page/4/${id}`, data)
        .then(({ data }) => {
          resolve(data);
          notistack.success(data.success)
        })
        .catch(({ response }) => {
          notistack.error(data.error)
          reject(response);
        });
    });
},
getSubscriptionData(){
  return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`page/6`)
        .then(({ data }) => {
          
          resolve(data);
        })
        .catch((error) => {reject(error)});
    });
},
setSubscriptionData(data,id) {
  
  return new Promise((resolve, reject) => {
    axios.setToken();
    axios
      .put(`update-page/6/${id}`,data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {reject(error)});
  });
},
getContactData(){
  return new Promise((resolve, reject) => {
    axios.setToken();
    axios
      .get(`page/2`)
      .then(({ data }) => {
        
        resolve(data);
      })
      .catch((error) => {reject(error)});
  });
},
setContactData(data,id) {
  
  return new Promise((resolve, reject) => {
    axios.setToken();
    axios
      .put(`update-page/2/${id}`,data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {reject(error)});
  });
},

  getFooter(){
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`page/7`)
        .then(({ data }) => {
          resolve(data);

        })
        .catch(({ response }) => {
          reject(response);

        });
    });
  },

  footerUpdate(data, id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .put(`update-page/7/${id}`, data)
        .then(({ data }) => {
          resolve(data);
          notistack.success(data.success)
        })
        .catch(({ response }) => {
          notistack.error(data.error)
          reject(response);
        });
    });
  },

  getInscription(){
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`page/5`)
        .then(({ data }) => {
          resolve(data);

        })
        .catch(({ response }) => {
          reject(response);

        });
    });
  },
  getLoginPage(){
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`page/8`)
        .then(({ data }) => {
          resolve(data);

        })
        .catch(({ response }) => {
          reject(response);

        });
    });
  },
  getMyProfilePage(){
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`page/9`)
        .then(({ data }) => {
          resolve(data);

        })
        .catch(({ response }) => {
          reject(response);

        });
    });
  },
  inscriptionUpdate(data, id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .put(`update-page/5/${id}`, data)
        .then(({ data }) => {
          resolve(data);
       
        })
        .catch(({ response }) => {
           reject(response);
        });
    });
  },
  loginUpdate(id,data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .put(`update-page/8/${id}`, data)
        .then(({ data }) => {
          resolve(data);
       
        })
        .catch(({ response }) => {
           reject(response);
        });
    });
  },
  myProfileUpdate(id,data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .put(`update-page/9/${id}`, data)
        .then(({ data }) => {
          resolve(data);
       
        })
        .catch(({ response }) => {
           reject(response);
        });
    });
  },

};
export default pages;
