import React, { useState, useMemo, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as pickersLocales from '@mui/x-date-pickers';
import * as coreLocales from '@mui/material/locale';
import * as gridLocales from '@mui/x-data-grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
    AppBar,
    Box,
    CssBaseline,
    Toolbar
} from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Header,
    Menu,
    Breadcrumbs,
    Setting
} from './Main';
import { languages } from '../../lang';
import { AppContext } from "../../plugin/AppContext";

const drawerWidth = 250;
const Main = styled('main')(({ theme, open, themOpen }) => ({
    padding: '24px',
    width: '100%',
    ...((!open || themOpen) && {
        [theme.breakpoints.up('md')]: {
            marginLeft: !open && -drawerWidth,
            marginRight: themOpen && drawerWidth,
        }
    })
}));

export default function AppLayout() {
    const { i18n } = useTranslation();
    const contextObj = useContext(AppContext);
    const language = i18n.language;
    const [primaryColor, setPrimaryColor] = useState('#2196f3')
    const [darkMode, setDarkMode] = useState(false)
    const [themOpen, seThemOpen] = useState(false);
    const theme = useMemo(() => createTheme(
        {
            palette: {
                primary: { main: primaryColor },
                mode: darkMode ? 'dark' : "light",
            },
        },
        pickersLocales[language],
        gridLocales[language],
        coreLocales[language]
    ), [darkMode, primaryColor, language]);
    const [user, setUser] = useState(null);
    const [open, setMobileOpen] = useState(true);

    useEffect(() => {
        contextObj.user ? setUser(contextObj.user) : contextObj.getAllData()
    }, [contextObj.user]);

    if (!user) return null;
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={languages[language].dayjs}>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                        <Header handleDrawer={() => setMobileOpen(!open)} user={user} handleThemDrawer={() => seThemOpen(!themOpen)} />
                    </AppBar>
                    <Menu
                        drawerWidth={drawerWidth}
                        open={open}
                        handleDrawer={setMobileOpen}
                        onClick={() => seThemOpen(!themOpen)}
                    />
                    <Main className='main' sx={{ backgroundColor: darkMode ? "" : "#f5f5f5" }} open={open} themopen={themOpen}>
                        <Toolbar />
                        <Breadcrumbs />
                        <Outlet />
                        <Setting
                            drawerWidth={drawerWidth}
                            themopen={themOpen}
                            handleThemDrawer={() => seThemOpen(!themOpen)}
                            primaryColor={primaryColor}
                            setPrimaryColor={setPrimaryColor}
                            darkMode={darkMode}
                            setDarkMode={setDarkMode}
                        />
                    </Main>
                </Box>
            </LocalizationProvider>
        </ThemeProvider>
    );
}