import {
    Home as HomeIcon,
    Groups as GroupsIcon,
    FormatListBulleted as FormatListBulletedIcon
} from '@mui/icons-material';
import LayersIcon from '@mui/icons-material/Layers';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import QuizIcon from '@mui/icons-material/Quiz';
import ArticleIcon from '@mui/icons-material/Article';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ContactPageIcon from '@mui/icons-material/ContactPage';

const menuItems = [
    { id: 0, label: 'menu.dashboard', link: '/', icon: <DashboardIcon color='primary' /> },
    { id: 1, label: 'menu.pages', link: './pages', icon: <AutoStoriesIcon color='secondary' /> },
    // { id: 2, label: 'menu.home', icon: <HomeIcon color='secondary' />, link: './home' },
    // { id: 3, label: 'menu.abonnement', icon: <AdsClickIcon color='secondary' />, link: './abonnement' },
    // { id: 4, label: 'menu.contact', icon: <ContactPageIcon color='secondary' />, link: './contact' },
    // { id: 5, label: 'menu.faq', icon: <QuizIcon color='secondary' />, link: './faq' },
    // { id: 6, label: 'menu.mentions_légales', icon: <ArticleIcon color='secondary' />, link: './mentions-légales' },
    // { id: 7, label: 'menu.inscription', icon: <ArticleIcon color='secondary' />, link: './inscription' },
];

export default menuItems;