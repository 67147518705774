import Router from './plugin/routes';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Axios } from './components/Loader';
import { SnackbarUtilsConfigurator } from './plugin/notistack';
import { ApplicationDataProvider, AppDataConsumer } from "./plugin/AppContext";

export default function App() {
    return (
        <BrowserRouter basename="/">
            <SnackbarProvider maxSnack={5}>
                <Axios />
                <SnackbarUtilsConfigurator />
                <ApplicationDataProvider>
                    <AppDataConsumer>
                        {() => { return <Router /> }}
                    </AppDataConsumer>
                </ApplicationDataProvider>
            </SnackbarProvider>
        </BrowserRouter>
    );
}
