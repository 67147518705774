import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Drawer,
  Toolbar,
  List,
  useMediaQuery,
  Box,
  Typography,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function Setting({
  drawerWidth,
  themopen,
  handleThemDrawer,
  setPrimaryColor,
  darkMode,
  setDarkMode,
}) {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const Color = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2979ff",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#ff3d00",
  ];
  const templet = Color.map((item, index) => (
    <Button
      key={index}
      style={{
        backgroundColor: item,
        width: "70px",
        height: "40px",
        borderRadius: "0px",
      }}
      onClick={(e) => setPrimaryColor(item)}
    ></Button>
  ));

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
    >
      <Drawer
        anchor="right"
        open={themopen}
        onClose={() => handleThemDrawer(!themopen)}
        sx={{
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            borderRadius: "8px 0px 0px 8px",
          },
          zIndex: 1300,
        }}
      >
        <Toolbar />
        <PerfectScrollbar component="div">
          <List>
            <Typography sx={{ paddingLeft: "20px", fontWeight: "500" }}>
              Mode
            </Typography>
            <ToggleButtonGroup
              color="primary"
              exclusive
              value={darkMode ? "0" : "1"}
              sx={{ paddingLeft: "20px", paddingTop: "5px" }}
            >
              <ToggleButton
                value="0"
                onClick={() => setDarkMode(true)}
                sx={{ borderRadius: "10px" }}
              >
                Dark Mode
              </ToggleButton>
              <ToggleButton
                value="1"
                onClick={() => setDarkMode(false)}
                sx={{ borderRadius: "10px" }}
              >
                Light mode
              </ToggleButton>
            </ToggleButtonGroup>
            <Divider sx={{ marginTop: "22px" }} color="black" />
            <Typography
              sx={{
                paddingLeft: "20px",
                paddingTop: "10px",
                fontWeight: "500",
              }}
            >
              PrimaryColor
            </Typography>
            <Box sx={{ paddingLeft: "20px", paddingTop: "8px" }}>{templet}</Box>
            <Divider sx={{ marginTop: "22px" }} color="black" />
          </List>
        </PerfectScrollbar>
      </Drawer>
    </Box>
  );
}
