import axios from '../plugin/axios';
import notistack from '../plugin/notistack';
const ID_TOKEN_KEY = 'tokens';

const auth = {
    login(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('login', data)
                .then(({ data }) => {
                    this.saveToken(data.token);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    getUser() {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('get-auth-user')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.destroyToken();
                    reject(response);
                });
        });
    },

    getPages() {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('pages')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.destroyToken();
                    reject(response);
                });
        });
    },

    logout() {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('logout')
                .then(({ data }) => {
                    notistack.success(data.message);
                    this.destroyToken();
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.destroyToken();
                    reject(response);
                });
        });
    },
    forgotPassword(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('forgotPassword', data)
                .then(({ data }) => {
                    notistack.success(data.message);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    resetPassword(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('resetPassword', data)
                .then(({ data }) => {
                    notistack.success(data.message);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    resend() {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('resend')
                .then(({ data }) => {
                    notistack.success(data.message);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    verify(data) {
        return new Promise((resolve, reject) => {
            axios
                .post('emailVerify', data)
                .then(({ data }) => {
                    notistack.success(data.message);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    profile(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('auths', 'profile_update', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    changePassword(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .post('change_password', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    getToken() {
        return window.localStorage.getItem(ID_TOKEN_KEY);
    },
    saveToken(token) {
        window.localStorage.setItem(ID_TOKEN_KEY, token);
    },
    destroyToken() {
        window.localStorage.removeItem(ID_TOKEN_KEY);
    },
};
export default auth;