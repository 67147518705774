const menu = {
    home: 'Domicile',
    dashboard: 'Tableau de bord',

    pages: 'Pages',
    update_page: 'Mettre à jour la page',

    blogs: 'Blogues',
    new_blog: 'Nouveau blogue',
    update_blog: 'Mettre à jour le blogue',
    blog_details: 'Détails du blog',

    user: 'Utilisatrice',
    new_user: 'Nouvel utilisateur',
    update_user: 'Mettre à jour l utilisateur',
    user_details: 'Détails de l`utilisateur',

    search: 'Recherche..',
    messages: 'Messages',
    notifications: 'Avis',
    profile: 'Profil',
    change_password: 'Changer le mot de passe',
    logout: 'Se déconnecter',

    update: 'mise à jour',
    create: 'Créer',
    edit: 'Modifier',
    view: 'Voir',
    convert: 'Convertir',
    send_email: 'Envoyer un e-mail',
    save: 'Sauvegarder',
    cancel: 'Annuler',

    contact: 'Contact',
    newsLetter: 'Lettre d`information',
    events: 'Événements',
    engagement: 'Engagement',
    donation: 'Don',

    index_pages:'Index des pages',
    abonnement: 'Abonnement',
    abonnement_index: 'Abonnement Index',
    faq: 'FAQ',
    mentions_legales: 'Mentions Légales',
    inscription:'Inscription',
    footer:'Footer',

    abonnement_update: 'Mise à jour des abonnements',
    abonnement_index_update: `Abonnement à la mise à jour de l'index`,
    faq_update: 'Mise à jour des FAQ',
    mentions_légales_update: 'Mise à jour des mentions légales',
    home_update: 'Mise à jour de la maison',
    footer_update:'Mise à jour du pied de page',
};
export default menu;