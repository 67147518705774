const auth = {
    email: 'Email address',
    password: 'Password',
    old_password: 'Old Password',
    confirm_password: 'Confirmation Password',
    login: 'Login',
    remember_me: 'Remember me',
    login_forgot_password: 'Forgot password?',
    forgot_password: 'Forgot Password',
    already_have_an_account: 'Already have an account?',
    password_reset: 'Password Reset',
    email_verify_message: 'Thanks for signing up! Before getting started, could you verify your email address by clicking on the link we just emailed to you? If you didn\'t receive the email, we will gladly send you another.',
    resend_verification_email: 'Resend Verification Email',
    password_changed_successfully: 'Password Changed Successfully',
    profile_updated_successfully: 'Profile Updated Successfully',
    reset_link_is_sent_to_your_email: 'Reset link is sent to your email',
    login_successfully: 'Login successfully',
};
export default auth;