import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Avatar,
    MenuItem,
    ListItemIcon,
    Divider
} from '@mui/material';
import {
    Settings as SettingsIcon,
    Logout as LogoutIcon,
} from '@mui/icons-material';
import { CustomMenu } from '../../../UI';
import { auth } from '../../../../models';

export default function Account({ user }) {
    const menu = useRef(null)
    const navigate = useNavigate();
    const { t } = useTranslation();

    const logout = () => {
        auth.logout().then(() => {
            navigate('/login');
        }).catch(() => {
            navigate('/login');
        });
    }
    return (
        <CustomMenu
            id='account-menu'
            size='medium'
            color='inherit'
            icon={<Avatar alt={user.first_name} src={user.image_path} sx={{ width: 32, height: 32 }} />}
            childFunc={menu}
        >
            <MenuItem onClick={() => {
                menu.close();
                navigate('/profile');
            }} >
                <Avatar alt={user.first_name} src={user.image_path} /> {user.first_name}
            </MenuItem>
            <MenuItem onClick={() => {
                menu.close();
                navigate('/change-password');
            }} >
                <ListItemIcon>
                    <SettingsIcon fontSize='small' />
                </ListItemIcon>
                {t('menu.change_password')}
            </MenuItem>
            <Divider />
            <MenuItem onClick={logout} >
                <ListItemIcon>
                    <LogoutIcon fontSize='small' />
                </ListItemIcon>
                {t('menu.logout')}
            </MenuItem>
        </CustomMenu>
    );
}