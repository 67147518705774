const common = {
    action: 'Action',
    delete: 'Supprimer',
    duplicate: 'Dupliquer',
    cancel: 'Annuler',
    save: 'Sauver',
    update: 'Mise à jour',

    disagree: 'Être en désaccord',
    agree: 'Se mettre d\'accord',
    delete_data: 'Suprimmer les données?',
    delete_data_message: 'Êtes-vous sûr de vouloir supprimer ces données ?',
    user_update_successfully: 'Mise à jour de l`utilisateur réussie',
    user_created_successfully: 'Utilisateur créé avec succès',
    blog_updated: 'Blog mis à jour',
    blog_created: 'Blog créé',
    donation_created: 'Don créé',
    page_update_successfully: 'Mise à jour de la page réussie'
};
export default common;