const attribute = {
    // Leads
    name: 'Name',
    first_name: 'Prénom',
    last_name: 'Nom de famille',
    full_name: 'Nom et prénom',
    email: 'E-mail',
    closing_date: 'Date de clôture',
    password: 'Mot de passe',
    conform_password: 'Conformez le mot de passe',
    phone: 'Téléphone',
    country: 'De campagne',
    background: 'Arrière plan',
    date: 'Date',
    comments: 'commentaires',
    testimony: 'Témoignage',
    read_all: 'Lis tout',
    age: 'Âge',

    id: 'Identifiant',
    identifier: 'Identifiant',
    page: 'Page',
    container: 'Récipient',
    text: 'Texte',
    images: 'Images',
    detailed_image: 'Image détaillée',
    avatar: 'Avatar',
    icon: 'Icône',
    map: 'Carte',
    buttons: 'Boutons',
    form: 'Formulaire',
    minimum_date: 'Date minimale',
    maximum_date: 'Date maximale',
    select_date: 'Sélectionner une date',
    selected_date: 'Date sélectionnée',

    title: 'Titre',
    en_title: 'En Titre',
    fr_title: 'Fr Titre',
    short_description: 'Brève Description',
    en_short_description: 'En brève description',
    fr_short_description: 'Fr brève description',
    en_long_description: 'En longue description',
    fr_long_description: 'Fr longue description',
    en_category: 'En Catégorie',
    fr_category: 'Fr Catégorie',
    view_count: 'Nombre de vues',
    authore: 'Auteur',
    en_authore: 'En Auteur',
    fr_authore: 'Fr Auteur',
    status: 'Statut',
    subject: 'Matière',
    message: 'Message',
    language: 'Langue',
    category: 'Catégorie',

    minimum: 'Le minimum',
    maximum: 'Maximum',
    actual_value: 'Valeur actuelle',

    text_en: 'Texte En',
    text_fr: 'Texte Fr'
};
export default attribute;