import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
    Drawer,
    Toolbar,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    useMediaQuery,
    Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import menuItem from './MenuItems.js';

export default function Menu({ drawerWidth, open, handleDrawer }) {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const [isSidebarOpened, setIsSidebarOpened] = useState(0);

    const linkCheck = (link) => {
        if (link === '/') {
            return link === location.pathname
        }
        return (location.pathname).includes(link)
    };
    const childrenCheck = (row) => {
        const isItemSelected = isSidebarOpened === row.id;
        const childrenSelected = (row.children && row.children.some((chil) => {
            const selected = linkCheck(chil.link);
            return selected ? selected : childrenCheck(chil)
        })) || false;
        return isSidebarOpened === 0 ? (isItemSelected || childrenSelected) : isItemSelected;
    };
    const SidebarLink = ({
        id,
        row,
        link,
        icon,
        label,
        children
    }) => {
        const [isOpen, setIsOpen] = useState(childrenCheck(row));

        if (!children) {
            return (
                <ListItem
                    button
                    onClick={() => {
                        handleDrawer(matchUpMd);
                        navigate(link);
                    }}
                    selected={linkCheck(link)}
                    disableRipple
                >
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={t(label)} />
                </ListItem>
            )
        } else {
            return (
                <>
                    <ListItem
                        button
                        onClick={() => {
                            setIsOpen(!isOpen);
                            setIsSidebarOpened(id);
                        }}
                        selected={linkCheck(link)}
                        disableRipple
                    >
                        <ListItemIcon>
                            {icon}
                        </ListItemIcon>
                        <ListItemText primary={t(label)} />
                        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>
                    {children && (
                        <Collapse
                            in={isOpen}
                            timeout='auto'
                            unmountOnExit
                        >
                            <List component='div' disablePadding>
                                {children.map(childrenLink => (
                                    <SidebarLink
                                        key={childrenLink.id}
                                        row={childrenLink}
                                        nested
                                        {...childrenLink}
                                    />
                                ))}
                            </List>
                        </Collapse>
                    )}
                </>
            )
        }
    }

    return (
        <Box component='nav' sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}>
            <Drawer
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor='left'
                open={open}
                onClose={() => handleDrawer(!open)}
                sx={{
                    [`& .MuiDrawer-paper`]: {
                        width: drawerWidth,
                        boxSizing: 'border-box'
                    },
                }}
            >
                <Toolbar />
                <PerfectScrollbar component='div'>
                    <List>
                        {menuItem.map((link) => (
                            <SidebarLink
                                key={link.id}
                                row={link}
                                {...link}
                            />
                        ))}
                    </List>
                </PerfectScrollbar>
            </Drawer>
        </Box>
    );
}