import common from './common';
import auth from './auth';
import error from './error';
import menu from './menu';
import attribute from './attribute';
const frFR = {
    ...common,
    ...{
        auth,
        error,
        menu,
        attribute
    }
}
export default frFR;