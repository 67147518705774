import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Card,
    CardHeader,
    CardContent
} from '@mui/material';

export default function Home() {
    const { t } = useTranslation();
    return (
        <Card>
            <CardHeader title={t('menu.dashboard')} />
            <CardContent>
                Home
            </CardContent>
        </Card>
    );
}