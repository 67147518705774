const common = {
    action: 'Action',
    delete: 'Delete',
    duplicate: 'Duplicate',
    cancel: 'Cancel',
    save: 'Save',
    update: 'Update',

    disagree: 'Disagree',
    agree: 'Agree',
    delete_data: 'Delete data?',
    delete_data_message: 'Are you sure you want to delete this data?',
    user_update_successfully: 'User update successfully',
    user_created_successfully: 'User created successfully',
    blog_updated: 'Blog updated',
    blog_created: 'Blog created',
    donation_created: 'Donation created',
    page_update_successfully: 'Page update successfully'
};
export default common;
