import {
    Box,
    LinearProgress,
    CircularProgress,
    Backdrop
} from '@mui/material';

const Loader = () => (
    <Box className='pre-loader'>
        <LinearProgress className='linear-loader' color='primary' />
        <Backdrop className='circular-loader' open={true} >
            <CircularProgress color='inherit' size={64} disableShrink thickness={3} />
        </Backdrop>
    </Box>
);

export default Loader;
