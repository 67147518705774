import enUS from './enUS';
import ENIcon from '../assets/images/flag/enUS.svg';
import enLocale from 'dayjs/locale/en-in';

import frFR from './frFR';
import FRIcon from '../assets/images/flag/frFR.svg';
import frLocale from 'dayjs/locale/fr';

export const resources = {
    enUS: {
        translation: enUS
    },
    frFR: {
        translation: frFR
    }
};

export const languages = {
    enUS: {
        name: 'English',
        code: 'en',
        icon: ENIcon,
        dayjs: enLocale
    },
    frFR: {
        name: 'French',
        code: 'fr',
        icon: FRIcon,
        dayjs: frLocale
    }
};