import React from 'react';
import { Navigate, useRoutes, Outlet } from 'react-router-dom';
import { Lazy } from '../components/Loader';
import Home from '../views/Home';
import {
	App as AppLayout,
	Login as LoginLayout
} from '../components/layout';

export default function Router() {
	return useRoutes([
		{
			path: '/',
			element: <AppLayout />,
			children: [
				{ path: '/', element: <Home /> },
				{ path: '/profile', element: <Lazy view={'Account/Profile'} /> },
				{ path: '/change-password', element: <Lazy view={'Account/ChangePassword'} /> },
				{
					path: '/pages',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Pages/Index'} /> },
					]
				},
				{
					path: '/home',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Home/View'} /> },
					]
				},
				
				{
					path: '/abonnement',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Abonnement/View'} /> },
					]
				},
				{
					path: '/contact',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Contact/View'} /> },
					]
				},
				{
					path: '/faq',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'FAQ/View'} /> },
					]
				},
				{
					path: '/mentions_legales',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Mentions-Légales/View'} /> },
					]
				},
				{
					path: '/inscription',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Inscription/View'} /> },
					]
				},
				{
					path: '/footer',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Footer/View'} /> },
					]
				},
				{
					path: '/loginview',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'Login/View'} /> },
					]
				},
				{
					path: '/myprofile',
					element: <Outlet />,
					children: [
						{ path: '', element: <Lazy view={'MyProfile/View'} /> },
					]
				},
			]
		},
		{
			path: '/',
			element: <LoginLayout />,
			children: [
				{ path: '/login', element: <Lazy view={'Auth/Login'} /> },
				{ path: '/forgot-password', element: <Lazy view={'Auth/ForgotPassword'} /> },
				{ path: '/password-reset/:token', element: <Lazy view={'Auth/PasswordReset'} /> },
				{ path: '/verify', element: <Lazy view={'Auth/EmailVerify'} /> },
				{ path: '/verify/:token/:email', element: <Lazy view={'Auth/EmailVerify'} /> },
			]
		},
		{ path: 'error', element: <Lazy view={'Error'} /> },
		{ path: '*', element: <Navigate to='/error' replace /> }
	]);
}